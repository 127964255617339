<template>
  <div class="wrap" id="wrap">
    <!-- 按钮 -->
    <div class="btnBox" id="search-card">
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="confirm()"
        >保存
      </el-button>
      <el-button icon="el-icon-refresh" size="mini" @click="getList()">刷新</el-button>
    </div>
    <cardTitleCom cardTitle="收款方式限购">
      <template slot="cardContent">
        <div class="btns-box">
          <el-button type="primary" size="mini" @click="handleEvent('openGoodsDialog')"
            >选择商品</el-button
          >
          <el-button type="primary" size="mini" @click="handleEvent('openBrandDialog')"
            >选择品牌</el-button
          >
          <el-button type="primary" size="mini" @click="handleEvent('openCategoryDialog')"
            >选择类别</el-button
          >
        </div>
        <el-form ref="form" :model="form" :rules="rules" size="mini">
          <div class="table-box">
            <vxe-table
              border
              show-overflow
              keep-source
              show-header-overflow
              ref="xTable"
              :data="form.list"
              :loading="loading"
              :loading-config="{ text: '加载中' }"
              empty-text="暂无数据"
              :max-height="tableHeight"
              :height="tableHeight"
              :checkbox-config="{ trigger: 'row' }"
              :row-config="{ isHover: true, height: 60 }"
              :sort-config="{ trigger: 'cell' }"
              :scroll-y="{ enabled: true }"
              :column-config="{ resizable: true }"
            >
              <vxe-column
                align="center"
                :title="tableCellLabel"
                width="80"
                fixed="left"
                type="seq"
              >
                <template v-slot="scope">
                  <div
                    @mouseenter="cellMouseEnter(scope.row)"
                    @mouseleave="cellMouseLeave(scope.row)"
                  >
                    <i
                      v-show="scope.row.hoverRow"
                      :disabled="disabled"
                      @click="delRow(scope.rowIndex)"
                      class="el-icon-remove operateDel"
                    ></i>
                    <div v-show="!scope.row.hoverRow">
                      {{ scope.rowIndex + 1 }}
                    </div>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                title="行类型"
                align="center"
                field="lineTypeName"
                width="160"
                fixed="left"
              >
              </vxe-column>
              <vxe-column
                title="行编号"
                align="center"
                field="lineNo"
                width="160"
                fixed="left"
              >
              </vxe-column>
              <vxe-column
                title="行描述"
                align="center"
                field="lineName"
                width="160"
                fixed="left"
              >
              </vxe-column>
              <vxe-column
                title="适用门店"
                align="center"
                field="shopIds"
                width="280"
                fixed="left"
              >
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>适用门店</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`list[${scope.rowIndex}].shopIds`"
                    :rules="rules[`list.shopIds`]"
                  >
                    <SelectLocal
                      :multiple="true"
                      v-model="scope.row.shopIds"
                      style="width: 250px"
                      @selectChange="selectShopChange(scope.row.shopIds, scope.row)"
                      :option="{
                        data: listShop,
                        labels: [
                          { title: '编号', label: 'shopNo' },
                          { title: '名称', label: 'shopName' },
                        ],
                        value: 'shopId',
                        label: 'shopName',
                      }"
                    />
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column
                :title="item.payModeName"
                align="center"
                field="goodsSpec"
                width="120"
                v-for="item in listPay"
                :key="item.payModeId"
              >
                <template slot-scope="scope">
                  <el-checkbox
                    @change="updateList.push(scope.row)"
                    v-model="
                      scope.row.shopPayModeLimitPayModeIsLimitVos[
                        scope.row.shopPayModeLimitPayModeIsLimitVos.findIndex(
                          (i) => i.payModeId === item.payModeId
                        )
                      ].isLimit
                    "
                  ></el-checkbox>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </el-form>
      </template>
    </cardTitleCom>
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import { listShopInfo } from "@/api/shop/base/shopInfo"; //门店
import { listPaymode } from "@/api/shop/base/paymode.js"; //付款方式
import { listPaymodeLimit, savePaymodeLimit } from "@/api/shop/base/paymodeLimit.js"; //付款方式
import { uniqWith, cloneDeep } from "lodash"; //去重

export default {
  name: "PaymodeLimit",
  components: { cardTitleCom, SelectLocal, Dialog },
  data() {
    return {
      loading: false,
      tableHeight: 0,
      form: {
        list: [],
      },
      tableCellLabel: "序号",
      listShop: [], // 门店数据
      listPay: [], //门店付款方式
      delList: [], // 删除的数据
      updateList: [], // 修改的数据
      //弹窗配置
      dialogOptions: {
        curType: "", //当前类型(商品/品牌/类别)
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      rules: {
        "list.shopIds": [
          {
            required: true,
            message: "请选择门店",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.getShopList();
      await this.getPayModeList();
      await this.getList();
    } catch (err) {
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      setTimeout(() => {
        const wrapH = document.getElementById("wrap")?.clientHeight || 0;
        this.tableHeight = wrapH - 140;
      }, 10);
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (!this.form.list?.length) return;
      this.$set(row, "hoverRow", true);
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(row) {
      // 移除hover的事件
      this.$set(row, "hoverRow", false);
      this.tableCellLabel = "序号";
    },
    //选择门店
    selectShopChange(val, row) {
      if (val.some((item) => item == 0)) {
        this.$set(row, "shopIds", [0]);
      }
      this.updateList.push(row);
    },
    //表格减少一行方法
    delRow(index) {
      this.delList.push(this.form.list[index]?.lineId);
      this.form.list.splice(index, 1);
    },
    // 获取门店数据
    async getShopList() {
      try {
        //门店列表
        const res = await listShopInfo({ pageNum: 1, pageSize: 10000000 });
        // getAllShopInfo
        this.listShop = res.rows;
        this.listShop.unshift({
          shopId: 0,
          shopNo: 0,
          shopName: "全部门店",
        });
      } catch (err) {}
    },
    async getPayModeList() {
      try {
        const { rows } = await listPaymode({ pageNum: 1, pageSize: 10000000 });
        this.listPay =
          rows?.map((item) => ({
            payModeName: item.payModeName,
            payModeId: item.payModeId,
            isLimit: false,
          })) || [];
        this.form.list[0].shopPayModeLimitPayModeIsLimitVos = rows || [];
      } catch (err) {}
    },
    // 获取列表
    async getList() {
      try {
        if (!this.loading) this.loading = true;
        const { rows = [] } = await listPaymodeLimit({ pageNum: 1, pageSize: 10000000 });
        this.form.list =
          rows.map((item) => ({
            ...item,
            shopPayModeLimitPayModeIsLimitVos: this.listPay.map((i) => ({
              payModeId: i.payModeId,
              isLimit:
                item.shopPayModeLimitPayModeIsLimitVos.find(
                  (x) => x.payModeId === i.payModeId
                )?.isLimit || false,
            })),
          })) || [];
      } catch (err) {
        this.$message.warning("获取数据失败,请刷新!");
      } finally {
        this.loading = false;
      }
    },
    //处理表格数据(商品/类别/品牌)
    getTableData(type, list) {
      const lineTypeMap = {
        goodsId: {
          lineType: 1,
          lineTypeName: "商品",
          idKey: "goodsId",
          nameKey: "goodsName",
          noKey: "goodsNo",
        },
        categoryId: {
          lineType: 3,
          lineTypeName: "类别",
          idKey: "categoryId",
          nameKey: "categoryName",
          noKey: "categoryNo",
        },
        brandId: {
          lineType: 2,
          lineTypeName: "品牌",
          idKey: "brandId",
          nameKey: "brandName",
          noKey: "brandNo",
        },
      };

      const lineTypeConfig = lineTypeMap[type];
      if (!lineTypeConfig) return [];

      return list.map((item) => ({
        [lineTypeConfig.idKey]: item[lineTypeConfig.idKey],
        lineType: lineTypeConfig.lineType,
        lineTypeName: lineTypeConfig.lineTypeName,
        lineId: item[lineTypeConfig.idKey],
        lineName: item[lineTypeConfig.nameKey],
        lineNo: item[lineTypeConfig.noKey],
        shopPayModeLimitPayModeIsLimitVos: cloneDeep(this.listPay),
      }));
    },
    async handleEvent(type, row) {
      // if (row?.curTable) this.curTable = row.curTable; //标识当前表格
      switch (type) {
        case "openGoodsDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "goodsId", //当前类型(门店/商品/类别/品牌)
            title: "选择商品",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "goods" }),
          };
          break;
        case "openBrandDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "brandId", //当前类型(门店/商品/类别/品牌)
            title: "选择品牌",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "brand" }),
          };
          break;
        case "openCategoryDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "categoryId", //当前类型(门店/商品/类别/品牌)
            title: "选择类别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "category" }),
          };
          break;
        case "dialogChange": //获取弹窗数据
          let list = [];
          let dialogType = this.dialogOptions.curType;
          //商品/类别/品牌
          if (this.dialogOptions.formData.table.check.length <= 0) return;
          list = this.dialogOptions.formData.table.check;
          list = this.getTableData(dialogType, list);
          list = uniqWith(list, (x, y) =>
            dialogType == "goodsId"
              ? x[dialogType] == y[dialogType] && x.unitId == y.unitId
              : x[dialogType] == y[dialogType]
          );
          // 已存在的行 过滤掉
          const newList = list.filter(
            (newItem) =>
              !this.form.list.some(
                (existingItem) => existingItem.lineId === newItem.lineId
              )
          );
          this.form.list = [...this.form.list, ...newList];
          break;
        default:
          break;
      }
    },
    async confirm() {
      try {
        try {
          await this.$refs.form.validate();
        } catch (err) {
          this.$message.error("请把带有红星的必填项输入完整!");
          return;
        }
        this.loading = true;
        await savePaymodeLimit({
          deleteIds: this.delList,
          shopPayModeLimitVos: this.updateList,
        });
        this.$message.success("保存成功!");
        await this.getList();
      } catch (err) {
        this.$message.error("保存失败,请重试!");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .btnBox {
    text-align: right;
    margin-bottom: 10px;
  }
  .table-box,
  .btns-box {
    padding: 5px;
  }
}
</style>
